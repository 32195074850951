import * as React from 'react'
import { Menu, MenuMenu, Dropdown, DropdownMenu, DropdownItem, MenuItem } from 'semantic-ui-react'

const handleLogout = async () => {
  const csrfToken = document.querySelector('[name="csrf-token"]').content;

  const response = await fetch('/users/sign_out', {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin', // Ensures cookies are sent with the request
  });

  if (response.ok) {
    window.location.href = '/';  // Redirect to home or wherever you'd like after logging out
  }
};

const LoggedInMenu = ({
  user,
  player_portal_path,
  admin_portal_path,
  logout_path,
  can_see_admin
}) => {
  return (
    <Dropdown item text={user.email}>
      <DropdownMenu>
        <DropdownItem>
          <a href={player_portal_path}>Player Portal</a>
        </DropdownItem>
        {can_see_admin && <DropdownItem><a href={admin_portal_path}>Admin Portal</a></DropdownItem>}
        <DropdownItem onClick={handleLogout}>
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
};

const LoggedOutMenu = ({login_path}) => {
  return (
    <MenuItem>
      <a href={login_path}>Log In</a>
    </MenuItem>
  );
}

const SocialMenu = () => {
  return (
    <Dropdown item text='Social'>
      <DropdownMenu>
        <DropdownItem>
          <a href='https://www.facebook.com/Legacy-of-the-Sith-LotS--323355740686/'>Facebook</a>
        </DropdownItem>
        <DropdownItem>
          <a href='https://discord.gg/rHq9qcc'>Discord</a>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

// Define the Navbar component
const Navbar = ({
  user,
  player_portal_path,
  admin_portal_path,
  logout_path,
  login_path,
  can_see_admin
}) => {
  // console.log('props', props);
  const logged_in_props = {
    user,
    player_portal_path,
    admin_portal_path,
    logout_path,
    can_see_admin
  };
  const logged_out_props = {
    login_path
  };

  return (
    <Menu>
      <MenuItem>Legacy of the Sith</MenuItem>
      <SocialMenu />
      <MenuItem>
        <a href='https://ko-fi.com/legacyofthesith'>Donate</a>
      </MenuItem>
      <MenuMenu position='right'>
        {user ? <LoggedInMenu {...logged_in_props} /> : <LoggedOutMenu {...logged_out_props} />}
      </MenuMenu>
    </Menu>
  )
};

export default Navbar;