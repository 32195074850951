import * as React from 'react'
import { Container } from 'semantic-ui-react'

import './mud.css';

// Define the Who component
const Who = ({ who }) => {
  return (
    <Container>
      <h1>Who's Online?</h1>
      <pre className="mud" dangerouslySetInnerHTML={{__html: who}}>      
      </pre>
      <h1>Play Now!</h1>
      <p>Connect to the game by pointing your favorite MUD client to: legacyofthesith.com:3000</p>
    </Container>
  )
};

export default Who;