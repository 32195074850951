import * as React from 'react'
import { AxisOptions, Chart } from "react-charts";

// Define the LagChart component
const LagChart = ({ data }) => {
  console.log('data', data);
  const axes = React.useMemo(
    () => [
      { primary: true, type: 'utc', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  )
  const primaryAxis = React.useMemo(() => ({
    getValue: (datum) => new Date(datum.created_at)
  }), []);

  const secondaryAxes = React.useMemo(() => ([
    {
      getValue: (datum) => datum.duration,
    },
  ]), []);
  

  return (
    <div style={{ width: '400px', height: '300px' }}>
      <Chart options={{data, primaryAxis, secondaryAxes}} />
    </div>
  )
};

export default LagChart;